import temperature_icon from '../../../../../../assets/medicalicons/thermometer.svg'
import hearth_rate_icon from '../../../../../../assets/medicalicons/cardiogram.svg'
import bp_icon from '../../../../../../assets/medicalicons/blood-pressure.svg'
import oxygen_icon from '../../../../../../assets/medicalicons/oxygen.svg'
import respiratory_rate_icon from '../../../../../../assets/medicalicons/human-lungs-outline.svg'

export const fakedata = {
  vitals:[
    {
      id:1, 
      title:'Temperatura', 
      last_report:{
        timestamp:'Nov 17,2019 08:00',
        value:36.5,
        unit:'°C'
      },
      image:temperature_icon,
      chart:{
        data:[],
        color:'#8884d8'
      }
    },
    {
      id:2, 
      title:'Rit. Cardiaco', 
      last_report:{
        timestamp:'Nov 18,2019 11:40',
        value:86,
        unit:'bpm'
      },
      image:hearth_rate_icon,
      chart:{
        data:[],
        color:'#8884d8'
      }
    },
    {
      id:3, 
      title:'Presión arterial', 
      last_report:{
        timestamp:'Nov 16,2019 09:10',
        value:'100/62',
        unit:'mmHg'
      },
      image:bp_icon,
      chart:{
        data:[],
        color:'#8884d8'
      }
    },
    {
      id:4, 
      title:'Saturación de Oxígeno', 
      last_report:{
        timestamp:'Nov 20,2019 09:10',
        value:'91',
        unit:'%'
      },
      image:oxygen_icon,
      chart:{
        data:[],
        color:'#8884d8'
      }
    },
    {
      id:5, 
      title:'Ritmo respiratorio', 
      last_report:{
        timestamp:'Nov 17,2019 09:10',
        value:'20',
        unit:'%'
      },
      image:respiratory_rate_icon,
      chart:{
        data:[],
        color:'#8884d8'
      }
    },
  ],
}