import React, { Component } from 'react';
import { BottomNavigation, BottomNavigationAction, Icon, withStyles } from '@material-ui/core';

const navURL = [
  '/',
  '/celudoc',
  '/medinube',
  '/celufarma',
  '/more'
]

class BottomBar extends Component {

  state = {
    selected:2,

  }

  render(){

    const {classes} = this.props
    const {selected} = this.state

    return(
      <div className={classes.root}>
        <BottomNavigation value={selected} onChange={this.onChange}>
          <BottomNavigationAction label='Home'  icon={<Icon>home</Icon>}/>
          <BottomNavigationAction label='Celudoc'  icon={<Icon>apartment</Icon>}/>
          <BottomNavigationAction label='Medinube'  icon={<Icon>cloud</Icon>}/>
          <BottomNavigationAction label='Farma'  icon={<Icon>shopping_cart</Icon>}/>
          <BottomNavigationAction label='Mas'  icon={<Icon>more_horiz</Icon>}/>
        </BottomNavigation>
      </div>
    )
  }

  onChange = (event, value) => {
    //console.log(value)
    this.setState({selected:value})
    this.props.onChangeView(navURL[value])
  }
}

const styles = theme => ({
  root:{
    position:'absolute',
    bottom:0,
    width:'100%'
  }
})

export default withStyles(styles)(BottomBar)