export const authActions = {
  LOGIN_REQUEST : 'LOGIN_REQUEST',
  LOGOUT : 'LOGOUT',
  LOGIN_SUCCESS : 'LOGIN_SUCCESS',
  LOGIN_ERROR : 'LOGIN_ERROR',
  REAUTH : 'REAUTH',
  AUTH_SAGA: 'AUTH_SAGA',
  AUTH_SAGE_REAUTH: 'AUTH_SAGE_REAUTH',
  USER_SAGA_LOGOUT: 'USER_SAGA_LOGOUT',
};

export const masterActions = {
  MASTER_PROCESS_STATE: 'POSTCLIENTS_PROCESS_STATE',
  MASTER_DATA_UPDATE: 'POSTCLIENTS_DATA_UPDATE',
  MASTER_START: 'MASTER_START',
  MASTER_SUCCESS: 'MASTER_SUCCESS',
  MASTER_ERROR: 'MASTER_ERROR',
  MASTER_CHANGE_LANGUAGE: 'MASTER_CHANGE_LANGUAGE',
  MASTER_UPDATE_CURRENT_USER: 'MASTER_UPDATE_CURRENT_USER',
  MASTER_GET_CURRENT_USER: 'MASTER_GET_CURRENT_USER',
  MASTER_SAGA_INITIALIZE:'MASTER_SAGA_INITIALIZE',
  MASTER_SAGA_UPDATE_LANGUAGE: 'MASTER_SAGA_UPDATE_LANGUAGE'
};

export const Home = {
  HOME_START: 'HOME_START',
  HOME_SUCCESS: 'HOME_SUCCESS',
  HOME_ERROR: 'HOME_ERROR',
  HOME_GET_CURRENT_USER: 'HOME_GET_CURRENT_USER',
  HOME_SAGA_INITIALIZE:'HOME_SAGA_INITIALIZE',
  HOME_PROCESS_STATE: 'HOME_PROCESS_STATE',
};

