import { grey } from '@material-ui/core/colors';
import {drawerWidth, closedDrawerWidth} from '../../variables/config'

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar:{
    boxSizing:'border-box',
    marginLeft:32,
    marginTop:4,
    marginRight:48,
    paddingLeft:16,
    paddingRight:16,
    background:theme.palette.primary.main,
    borderRadius:16,
    [theme.breakpoints.down('sm')]: {
      marginLeft:16,
      marginTop:4,
      marginRight:16,
      paddingLeft:16,
      paddingRight:16,
    }
  } , 
  appBar: {
    zIndex: theme.zIndex.drawer +1,
    boxShadow:'none',
    background: 'transparent',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    zIndex: theme.zIndex.drawer +1,
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    boxShadow:'none',
    background: 'transparent',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menu:{
    color:'white'
  },
  title:{
    marginTop: '9px',
    color:'white'
  },
  bor:{
    border: '1px solid red'
  }


  
});

export default styles;
