import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import {request_users, request_delete_user, request_welcome, request_zones, request_add_zone, request_delete_zone} from './requests'
import CardWrapper from '../../../../components/Cards/CardWrapper/CardWrapper';
import GeneralMenu from './components/GeneralMenu';
import { fakedata } from './fakedata.jsx';
import Vitals from './views/Vitals/Vitals';
import Demographics from './views/Demographics/Demographics';
import Documents from './views/Documents/Documents';
import Receipts from './views/Receipts/Receipts';
import Lab from './views/Lab/Lab';


class PatientView extends Component{

  state = {
    search:null,
    isloading:false,
    menuSelected:1,
    success:{open:false,timeout:2000,type:'success',message:'¡Acción exitosa!'},
  }

  render(){

    const {history, language} = this.props
    const {success, isloading, menuSelected} = this.state
    const content = contentData[language]

    let contentView = null

    switch (menuSelected) {
      case 1:
        contentView = <Vitals />
        break;
      case 2:
        contentView = <Demographics />
        break;
      case 3:
        contentView = <Documents />
        break;
      case 4:
        contentView = <Receipts />
        break;
      case 5:
        contentView = <Lab />
        break;
      default:
        break;
    }
    
    return(
      <BasicView
        history={history}
        content={content.layout}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar} 
        isloading={isloading}
      >
        <div>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <GeneralMenu 
                selected={menuSelected} 
                options={fakedata.menuList}
                onChange={this.onChangeMenu}/>
            </Grid>
            <Grid item xs={12}>
              {contentView}
            </Grid>
          </Grid>
        </div>
        
        
        
      </BasicView>
      
    )
  }

  componentDidMount(){
  }

  onChangeMenu = (id) => {
    this.setState({menuSelected:id})
  }



}

export default withStyles(styles)(PatientView)