import React, { Component } from 'react';
import { Grid, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Subtitle from '../../../../../../../components/Texts/Subtitle';
import CardWrapper from '../../../../../../../components/Cards/CardWrapper/CardWrapper';
import Caption from '../../../../../../../components/Texts/Caption';
import ContentText from '../../../../../../../components/Texts/ContentText';
import { fade } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';

import cx from 'classnames'

class RemindersCard extends Component {
  render(){

    const {classes} = this.props

    return(
      <Grid container>
        <Grid item xs={12}>
          <Subtitle text='Recordatorios' medium variant='light'/>
        </Grid>
        <Grid item xs={12}>
          <CardWrapper>
            <div className={classes.card}>
              <Scrollbars autoHeightMin="100%" maxHeight='300px'  style={{ height: 400 }}
                renderTrackHorizontal={props => <div {...props} style={{display: 'none', overflow:'hidden'}} className="track-horizontal"/>}
                renderView={props => (
                  <div {...props} style={{ ...props.style, overflowX: 'hidden', paddingLeft:4, paddingRight:12 }} />
              )}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ContentText text='Hoy' variant='bold' medium/>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        {content.today_reminders.map((item,key)=>{
                          return(
                            <Grid item xs={12} key={key.toString()}>
                              <ReminderItem data={item} color='primary'/>
                            </Grid>
                          )
                        })}
                        
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ContentText text='Mañana' variant='bold' medium/>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        {content.today_reminders.map((item,key)=>{
                          return(
                            <Grid item xs={12} key={key.toString()}>
                              <ReminderItem data={item} color='secondary'/>
                            </Grid>
                          )
                        })}
                        
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              </Scrollbars>
            </div>
          </CardWrapper>
        </Grid>
      </Grid>
    )
  }
}

const styles = theme => ({
  root:{},
  card:{
    padding:16,
    overflowX:'hidden',
    overflowY:'auto'
  }
})

export default withStyles(styles)(RemindersCard)

const content = {
  today_reminders:[
    {id:1, hour:'08:00', medicine:'Metformina', dose:1},
    {id:2, hour:'09:00', medicine:'Glucemia', dose:1},
    {id:3, hour:'12:00', medicine:'Metformina', dose:1},
    {id:4, hour:'16:00', medicine:'Glucemia', dose:1},
    {id:5, hour:'20:00', medicine:'Metformina', dose:1},
  ]
}


class ReminderItemW extends Component {

  render(){

    const {classes, data, color} = this.props

    return(
      <div>
        <Grid container wrap='nowrap'>
          <Grid item>
            <div className={cx({
              [classes.wrapA]:true,
              [classes.colorSecondaryA]:color === 'secondary'
            })}>
              <Grid container direction='column' alignItems='center' justify='center'>
                <Grid item>
                  <div style={{paddingTop:16}}>
                    <Caption text={data.hour} color='white'/>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs>
            <div className={cx({
              [classes.wrapB]:true,
              [classes.colorB]:color === 'secondary'
            })}>
              <Grid container direction='column'>
                <Grid item>
                  <Caption text={data.medicine} color='white'/>
                </Grid>
                <Grid item>
                  <Caption text={`${data.dose} tomas`} color='white'/>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
  
}

const styles_reminder = theme => ({
  root:{},
  wrapA:{
    background:fade(theme.palette.primary.main,1),
    boxSizing:'border-box',
    width:50,
    height:60,
    borderTopLeftRadius:8,
    borderBottomLeftRadius:8
  },
  colorSecondaryA:{
    background:fade(theme.palette.secondary.main,1),
  },
  
  wrapB:{
    background:fade(theme.palette.primary.light,0.8),
    padding:8,
    borderTopRightRadius:8,
    borderBottomRightRadius:8
  },
  colorB:{
    background:fade(theme.palette.secondary.light,0.8),
  },
})

const ReminderItem = withStyles(styles_reminder)(ReminderItemW)