import React, {Component} from 'react';
import { withStyles, fade } from '@material-ui/core/styles';
import { Typography, Grid, Icon } from '@material-ui/core';
import cx from 'classnames';
import { grey, blueGrey, blue, purple } from '@material-ui/core/colors';

import dashboard_icon from '../../../assets/sidebar/dashboard.svg'
import patient_icon from '../../../assets/sidebar/patient.svg'
import doctor_icon from '../../../assets/sidebar/doctor.svg'
import ReactSVG from 'react-svg';


const imageCatalog = {
  dashboard: dashboard_icon,
  patient: patient_icon,
  doctor: doctor_icon
}


const styles = (theme) => ({
  root:{
    width:'100%',
    color:grey[600],
    fontWeight:'500'
  },
  preDiv:{
    width:'100%',
    borderBottomRightRadius:16,
    background:'red'
  },  
  postDiv:{
    width:'100%',
  },  
  button:{
    width:'100%',
    boxSizing:'border-box',
    color:'white',
    borderRadius:16,
    padding:'16px 0px',
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover':{
      //background: fade(theme.palette.primary.main, 0.1) ,
      cursor:'pointer'
    }
  },
  buttonSelected:{
    background:theme.palette.custom.main,
    color:theme.palette.primary.main,
    borderTopRightRadius:0,
    borderBottomRightRadius:0,
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover':{
      cursor:'pointer',
      //background:theme.palette.primary.dark,
    }
  },
  imageSensor:{
    width:36,
    height:36
  }
})

class MenuBar extends Component {

  isCompatibleURL = (history, path) => {
    return history.location.pathname.split('/')[1] === path.split('/')[1];
  }

  render(){

    const {classes, history, routes, onClick} = this.props;

    return(
      <div className={classes.root}>
        <Grid container direction='column'>
          {routes.map((item,key)=>{
            if(item.sidebar){
              const selected =  this.isCompatibleURL(history,item.path);

              return(
                <Grid item key={key.toString()}>
                  <div 
                    onClick={() => onClick(item.path)}
                    className={cx({
                      [classes.button]:true,
                      [classes.buttonSelected]:selected,
                      
                    })}
                  >
                    <Grid container alignItems='center' justify='center' spacing={1}>
                      <Grid item>
                        <ReactSVG src={imageCatalog[item.icon]} className={classes.imageSensor} 
                      beforeInjection={svg => {svg.setAttribute('fill', selected ? '#C73794' : 'white' )}}/>
                      </Grid>
                      <Grid item>
                        <Typography variant='caption' style={{fontWeight:'500'}}>
                          {item.label}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              )
            }
            return null
          })}
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(MenuBar) 