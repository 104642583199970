import doctorh1 from '../../../../assets/doctors/doctor_h1.jpg'
import doctorh2 from '../../../../assets/doctors/doctor_h2.jpg'
import doctorh3 from '../../../../assets/doctors/doctor_h3.jpg'
import doctorm1 from '../../../../assets/doctors/doctor_m1.jpg'
import doctorm2 from '../../../../assets/doctors/doctor_m2.jpg'
//import doctorm3 from '../../../../assets/doctors/doctor_m3.jpg'

export const fakedata = {
  menuList: [
    {id:1, label:'Todo'},
    {id:2, label:'Neurología'},
    {id:3, label:'Ortopedia'},
    {id:4, label:'Ginecología'},
    {id:5, label:'Radioterapia'},
    {id:6, label:'General'},
  ],
  genderList: [
    {id:1, label:'Todo'},
    {id:2, label:'Hombre'},
    {id:3, label:'Mujer'},
  ],

  doctors:[
    {id:1, image:doctorm1, gender:'Mujer',name:'Gladys Patricia', speciality:'Neurología', email:'gpatricia@medinube.com', phone:'5512976919', address:'795 Folsom Ave, Suite 600 San Francisco, CADGE 94107'},
    {id:2, image:doctorh1, gender:'Hombre',name:'Eduardo Cárdenas', speciality:'Ortopedia', email:'ecardenas@medinube.com', phone:'5530678400', address:'795 Folsom Ave, Suite 600 San Francisco, CADGE 94107'},
    {id:3, image:doctorh2, gender:'Hombre',name:'Francisco Zamora', speciality:'General', email:'fzamora@medinube.com', phone:'5555099062', address:'795 Folsom Ave, Suite 600 San Francisco, CADGE 94107'},
    {id:4, image:doctorh3, gender:'Hombre',name:'Carlos Jimenez', speciality:'Neurología', email:'cjimenez@medinube.com', phone:'5550943334', address:'795 Folsom Ave, Suite 600 San Francisco, CADGE 94107'},
    {id:5, image:doctorh1, gender:'Hombre',name:'Luis Javier Barajas', speciality:'Ortopedia', email:'ljbarajas@medinube.com', phone:'5558708303', address:'795 Folsom Ave, Suite 600 San Francisco, CADGE 94107'},
    {id:6, image:doctorh2, gender:'Hombre',name:'David Suarez', speciality:'Ginecología', email:'dsuarez@medinube.com', phone:'5559811459', address:'795 Folsom Ave, Suite 600 San Francisco, CADGE 94107'},
    {id:7, image:doctorm2, gender:'Mujer',name:'Adriana Ríos', speciality:'Radioterapia', email:'arios@medinube.com', phone:'5557151670', address:'795 Folsom Ave, Suite 600 San Francisco, CADGE 94107'},
    {id:8, image:doctorh3, gender:'Hombre',name:'Rafael Hurtado', speciality:'General', email:'rhurtado@medinube.com', phone:'555101988', address:'795 Folsom Ave, Suite 600 San Francisco, CADGE 94107'},
    {id:9, image:doctorh1, gender:'Hombre',name:'Arturo Vega', speciality:'Ginecología', email:'avega@medinube.com', phone:'5555059792', address:'795 Folsom Ave, Suite 600 San Francisco, CADGE 94107'},  
  ]

}