export const fakedata = {
  dataSigns: [
    {
      name: "Temperatura",
      data: [36, 36.5, 37.1, 36.7, 36.3, 36.5, 36.4]
    },
    {
      name: "Glucosa",
      data: [12, 11, 14, 18, 17, 13, 13]
    },
    {
      name: "R.Cardiaco",
      data: [12, 11, 14, 18, 17, 13, 13]
    },
    {
      name: "P. Arterial",
      data: [12, 11, 14, 18, 17, 13, 13]
    },
    {
      name: "Sat. Oxígeno",
      data: [12, 11, 14, 18, 17, 13, 13]
    },
    {
      name: "R. Respiratorio",
      data: [12, 11, 14, 18, 17, 13, 13]
    },
  ]
}