import React, { Component } from 'react';
import { withStyles, Grid, Typography } from '@material-ui/core';
import CardWrapper from '../../../../components/Cards/CardWrapper/CardWrapper';
import ContentText from '../../../../components/Texts/ContentText';
import Caption from '../../../../components/Texts/Caption';
import { grey } from '@material-ui/core/colors';
import { palette } from '../../../../variables/config';
import Subtitle from '../../../../components/Texts/Subtitle';
import { RoundedButton } from '../../../../components/Buttons/GeneralButtons';



class ReminderCard extends Component {
  render(){

    const {classes, data} = this.props

    return(
      <div>
        <CardWrapper>
          <div className={classes.card}>
            <Grid container>
              <Grid item xs={12}>
                <ContentText text='Hoy' medium variant='bold'/>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                    {data.map((item,key)=>{
                      return(
                        <Grid item xs={12} key={key.toString()}>
                          <SingleRemider data={item} isLast={key===data.length-1}/>
                        </Grid>
                      )
                    })}
                
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify='flex-start'>
                  <Grid item>
                    <RoundedButton label='Mas' color='primary' size='small'/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </CardWrapper>
        
      </div>
    )
  }
}

const styles = theme => ({
  root:{},
  card:{
    padding:16
  },
  number:{
    paddingTop:8,
    paddingBottom:8,
    color:grey[900]
  },
  icon:{
    width:52,
    height:52
  }
})

export default withStyles(styles)(ReminderCard)

const SingleRemider = (props) => {

  const {data, isLast} = props

  return(
    <div style={{
      borderBottom: isLast ? null : `1px dashed ${grey[300]}`,
      paddingTop:8,
      paddingBottom:8
    }}>
      <Grid container>
        <Grid item xs>
          <Subtitle text={data.hour}  color={palette.primary.main}/>
        </Grid>
        <Grid item>
          <Caption text={data.medicine} medium/>
        </Grid>
      </Grid>
    </div>
    
  )
}
