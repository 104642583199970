import React, { Component } from 'react';
import { withStyles, AppBar, IconButton, Icon, Grid } from '@material-ui/core';
import ContentText from '../../../components/Texts/ContentText';
import { palette } from '../../../variables/config';
import { grey } from '@material-ui/core/colors';
import {fakedata} from './fakedata'

class FunctionalityView extends Component {
  render(){

    const {classes} = this.props

    return(
      <div className={classes.root}>
          <AppBar position='fixed' className={classes.appbar}>
            <div style={{position:'relative'}}>
              <div style={{padding:16}}>
                <ContentText text='Secciones' medium color={palette.primary.main} align='center'/>
              </div>
              <div style={{position:'absolute', top:'25%',left:16}}>
                <IconButton size='small' onClick={this.onReturn}>
                  <Icon color='primary'>arrow_back_ios</Icon>
                </IconButton>
              </div>
            </div>
          </AppBar>
          <div className={classes.container}>
            <Grid container>
              {fakedata.menu.map((item,key)=>{
                return(
                  <Grid item xs={12} key={key.toString()}>
                    <div 
                      onClick={() => this.onChangePage(item.url)}
                      className={classes.menuItem} 
                      style={{borderTop: key===0 ? `1px solid ${grey[300]}`:null}}>
                      <Grid container>
                        <Grid item xs>
                          <ContentText text={item.name} color={palette.primary.main}/>
                        </Grid>
                        <Grid item>
                        <Icon color='primary'>arrow_forward_ios</Icon>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                )
              })}
            </Grid>
          </div>
      </div>
    )
  }

  onReturn = () => {
    this.props.history.goBack()
  }

  onChangePage = (url) => {
    console.log(url)
    if(url){
      this.props.history.push(url)
    }
  }
}

const styles = theme => ({
  root:{
    position:'relative',
    background:'white',
    height:'100vh',
    overflow:'hidden'
  },
  container:{
    position:'fixed',
    bottom:56,
    background:'white',
  },
  appbar:{
    background:'white',
    boxShadow:'none',
    //borderBottomLeftRadius:32,
    //borderBottomRightRadius:32,
    borderBottom:`1px solid ${grey[300]}`
  },
  menuItem:{
    padding:24,
    borderBottom:`1px solid ${grey[300]}`
  }
})

export default withStyles(styles)(FunctionalityView)