import temperature_icon from '../../../assets/medicalicons/thermometer.svg'
import hearth_rate_icon from '../../../assets/medicalicons/cardiogram.svg'
import bp_icon from '../../../assets/medicalicons/blood-pressure.svg'
import oxygen_icon from '../../../assets/medicalicons/oxygen.svg'
import respiratory_rate_icon from '../../../assets/medicalicons/human-lungs-outline.svg'
import sugar_icon from '../../../assets/medicalicons/blood-drop.svg'

export const fakedata = {
  today_reminders:[
    {id:1, hour:'08:00', medicine:'Metformina', dose:1},
    {id:2, hour:'09:00', medicine:'Glucemia', dose:1},
    {id:3, hour:'12:00', medicine:'Metformina', dose:1},
    {id:4, hour:'16:00', medicine:'Glucemia', dose:1},
    {id:5, hour:'20:00', medicine:'Metformina', dose:1},
  ],
  vitals:[
    {
      id:1, 
      title:'Temperatura', 
      last_report:{
        timestamp:'Nov 17,2019 08:00',
        value:36.5,
        unit:'°C'
      },
      image:temperature_icon,
      chart:{
        data:[],
        color:'#8884d8'
      }
    },
    {
      id:2, 
      title:'R. Cardiaco', 
      last_report:{
        timestamp:'Nov 18,2019 11:40',
        value:86,
        unit:'bpm'
      },
      image:hearth_rate_icon,
      chart:{
        data:[],
        color:'#8884d8'
      }
    },
    {
      id:3, 
      title:'P. arterial', 
      last_report:{
        timestamp:'Nov 16,2019 09:10',
        value:'100/62',
        unit:'mmHg'
      },
      image:bp_icon,
      chart:{
        data:[],
        color:'#8884d8'
      }
    },
    {
      id:4, 
      title:'Sat. de Oxígeno', 
      last_report:{
        timestamp:'Nov 20,2019 09:10',
        value:'91',
        unit:'%'
      },
      image:oxygen_icon,
      chart:{
        data:[],
        color:'#8884d8'
      }
    },
    {
      id:5, 
      title:'R. respiratorio', 
      last_report:{
        timestamp:'Nov 17,2019 09:10',
        value:'20',
        unit:'%'
      },
      image:respiratory_rate_icon,
      chart:{
        data:[],
        color:'#8884d8'
      }
    },
    {
      id:6, 
      title:'Glucosa', 
      last_report:{
        timestamp:'Nov 17,2019 09:10',
        value:'92',
        unit:'mg/dl'
      },
      image:sugar_icon,
      chart:{
        data:[],
        color:'#8884d8'
      }
    },
  ],
}