import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';


class Vitals extends Component {
  render(){
    return(
      <div>
        Vitales
      </div>
    )
  }
}

const styles = theme => ({
  root:{}
})

export default withStyles(styles)(Vitals)