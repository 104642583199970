const styles = (theme) => ({
  root:{
    position:'relative',
    padding:16,
  },
  step_number:{
    color:theme.palette.primary.main,
  },
  icon:{
    color:theme.palette.primary.main,
    fontSize:64
  }
})

export default styles