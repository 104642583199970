import React, {Component} from 'react'
import { withStyles, Table, TableBody, TableRow, TableCell, Grid, Avatar, IconButton, Icon, Fab } from '@material-ui/core';
import ContentText from '../../../../../components/Texts/ContentText';
import Caption from '../../../../../components/Texts/Caption';
import { fade } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import PopperWrapper from '../../../../../components/Poppers/PopperWrapper';

class TableContent extends Component {
  render(){

    const {classes, data} = this.props

    return(
      <div className={classes.root}>
        <Table size="small">
          <TableBody>
            {data.map((item,key)=>{
              return(
                <TableRow className={classes.row} key={key.toString}>
                  <TableCell className={classes.cell}>
                    <div className={classes.cellWrapper}>
                      <Grid container alignItems='center' spacing={1}>
                        <Grid item xs>
                          <div className={classes.avatarCell}>
                            <AvatarData data={item}/>
                          </div>
                        </Grid>
                        <Grid item>
                          <InfoButton icon='mail_outline' data={{
                            label:'E-mail',
                            data:item.email
                          }}/>
                        </Grid>
                        <Grid item>
                          <InfoButton icon='phone' data={{
                            label:'Teléfono',
                            data:item.phone
                          }}/>
                        </Grid>
                        <Grid item>
                          <InfoButton icon='home' data={{
                            label:'Dirección',
                            data:item.address
                          }}/>
                        </Grid>
                      </Grid>
                      
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }
}

const styles = theme => ({
  root:{
    marginTop:16,
    paddingTop:16,
    borderTop:`1px dashed ${grey[300]}`,
    paddingBottom:16,

  },
  row:{
    margin:0,
  },
  cell:{
    border:0,
    margin:0,
  },
  cellWrapper:{
    padding:16,
    paddingTop:12,
    paddingBottom:12,
    borderRadius:8,
    background: fade(theme.palette.secondary.main,0.05)
  },
  avatarCell:{
    '&:hover':{
      cursor:'pointer'
    }
  }
})

export default withStyles(styles)(TableContent)


const AvatarData = (props) => {

  const {data} = props

  return(
    <div>
      <Grid container spacing={1}>
        <Grid item>
          <Avatar src={data.image} alt='Dr' />
        </Grid>
        <Grid item>
          <Grid container direction='column'>
            <Grid item>
              <Caption text={data.name} variant='bold'/>
            </Grid>
            <Grid item>
              <Caption text={data.speciality}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

class InfoButtonW extends Component {

  state = {
    open:false,
    anchorEl:null
  }

  render(){

    const {classes, icon, data} = this.props
    const {open, anchorEl} = this.state

    return(
      <div>
        <PopperWrapper
          open={open}
          anchorEl={anchorEl}
          onClickAway={this.onCloseMenu}
        >
          <div style={{padding:4, paddingLeft:8, paddingRight:8, maxWidth:200}}>
            <Grid container>
              <Grid item xs={12}>
                <Caption text={data.label} variant='light'/>
              </Grid>
              <Grid item xs={12}>
                <Caption text={data.data} variant='bold' medium/>
              </Grid>
            </Grid>
          </div>
        </PopperWrapper>
        <Fab size='small' className={classes.button} onClick={this.onOpenMenu}>
          <Icon fontSize='small' className={classes.icon}>
            {icon}
          </Icon>
        </Fab>
      </div>
    )
  }

  onCloseMenu = () => {
    this.setState({open: false,anchorEl: null});
  };
 

  onOpenMenu = (event) => {
    const { currentTarget } = event;
    this.setState({anchorEl: currentTarget,open: true});
  }
}

const button_styles = theme => ({
  root:{},
  button:{
    background:'white',
    boxShadow:'0 5px 24px 0 rgba(82, 91, 115, .12)',
    '&:hover':{
      background:'white',
    }
  },
  icon:{
    color:theme.palette.primary.main
  }
})

const InfoButton = withStyles(button_styles)(InfoButtonW)