import React, { Component } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import CardWrapper from '../../../../components/Cards/CardWrapper/CardWrapper';
import ReactApexChart from 'react-apexcharts';
import { palette } from '../../../../variables/config';
import ContentText from '../../../../components/Texts/ContentText';

class VitalChart extends Component{

  state = {
    options:{
      chart: {
        toolbar: {
          show: false
        }
      },
      colors: [palette.primary.main, '#545454'],
      dataLabels: {
          enabled: true,
      },
      stroke: {
          curve: 'smooth'
      },
      grid: {
          show:true,
          borderColor: '#e7e7e7',
          row: {
              colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
          },
          xaxis: {
            lines: {
              show: false
            }
          },   
          yaxis: {
            lines: {
              show: false
            }
          },  
      },
      fill:{
        colors: [palette.primary.main, '#545454'],
        opacity: 0.5,
        type: 'solid',
      },
      markers: {
          
          size: 8
      },
      xaxis: {
          labels:{
            show:false
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
      },
      yaxis: {
          min: 8,
          max: 20,
          labels:{
            show:false
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
      },
      legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
      },
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: false
      },
      labels: {
        show: false
      },
    },
    series: [{
      name: "Temperature",
      data: [12, 11, 14, 18, 17, 13, 13]
    }],
  }

  render(){

    const {classes, size} = this.props

    return(
      <div>
        <CardWrapper>
          <div className={classes.card}>
          <Grid container>
            <Grid item xs={12}>
              <div style={{paddingTop:8, paddingLeft:8}}>
                <ContentText text='Temperatura'  medium variant='bold'/>
              </div>
            </Grid>
            <Grid item xs={12}>
              <ReactApexChart 
                options={this.state.options} 
                series={this.state.series} 
                type="area" 
                height={size ? size : 250} />
            </Grid>
          </Grid>
          
          </div>
        </CardWrapper>
      </div>
    )
  }
}

const styles = theme => ({
  root:{},
  card:{
    padding:8
  }
})

export default withStyles(styles)(VitalChart)