import React, { Component } from 'react';
import { Paper, withStyles, Tabs, Tab } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const options = [
  {id:1, label:'Temperatura'},
  {id:2, label:'Glucosa'},
  {id:3, label:'R. Cardiaco'},
  {id:4, label:'P. Arterial'},
  {id:5, label:'Sat. Oxígeno'},
  {id:6, label:'R. Respiratorio'},
]

class VitalMenu extends Component {

  state = {
    selected:0,
  }

  render(){

    const {classes} = this.props
    const {selected} = this.state

    return(
      <Paper className={classes.paper}>
        <Tabs
          value={selected}
          onChange={this.onChange}
          
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{style: {backgroundColor: "transparent"}}}

        >
          {options.map((item,key)=>{
            return(
              <CustomTab label={item.label} key={key.toString()}/>
            )
          })}
        </Tabs>
      </Paper>
    )
  }

  onChange = (event, value) => {
    this.setState({selected:value})
  }
}

const styles = theme => ({
  root:{},
  paper:{
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
  }
})

export default withStyles(styles)(VitalMenu)

const CustomTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth:120,
    color:grey[400],
    '&:hover': {
      opacity: 1,
    },
    '&$selected': {
      background:theme.palette.primary.main,
      color: 'white',
      fontWeight: theme.typography.fontWeightMedium,
      borderRadius:4,
    },
    '&:focus': {
    },
  },
  selected: {},
}))(props => <Tab {...props} />);