import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers.jsx';
import rootSaga from './sagas.jsx';
import history from './history'

const routerMid = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, routerMid, sagaMiddleware];

const store = createStore(
  combineReducers({
    ...reducers,
    router:routerReducer
  }),
);

export { store, history};
