import React, { Component } from 'react';
import CardWrapper from '../../../../components/Cards/CardWrapper/CardWrapper';
import { Typography, Avatar, withStyles, Grid } from '@material-ui/core';

import profile_picture from '../../../../assets/profile_picture.jpg'
import Subtitle from '../../../../components/Texts/Subtitle';
import Title from '../../../../components/Texts/Title';
import { palette } from '../../../../variables/config';
import ContentText from '../../../../components/Texts/ContentText';
import Caption from '../../../../components/Texts/Caption';

class ProfileCard extends Component {
  render(){

    const {classes} = this.props

    return(
      <div className={classes.root}>
        <CardWrapper>
          <div style={{padding:16, position:'relative'}}>
            
            <Grid container spacing={3}>
              <Grid item>
                    <Avatar src={profile_picture} alt='' className={classes.avatar}/>
              </Grid>
              <Grid item xs>
                <Grid container>
                  <Grid item xs={12}>
                    <ContentText text='Buen día,' color={palette.primary.main} medium/>
                  </Grid>
                  <Grid item xs={12}>
                    <Subtitle text='Juan Diego' medium variant='bold'/>
                  </Grid>
                  <Grid item xs={12}>
                    <Caption text='ID: FC50569851' medium variant='light'/>
                  </Grid>
                </Grid>  
              </Grid>
            </Grid>
            <div >
              <Caption text='Lunes, 04 de dicimbre' medium variant='light' align='right'/>
            </div>
          </div>
        </CardWrapper>
        
        
      </div>
    )
  }
}

const styles = theme => ({
  root:{
  },
  avatar:{
    width:120,
    height:120
  }
})

export default withStyles(styles)(ProfileCard)