import React, { Component } from 'react';
import { Grid, Avatar, Icon, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Subtitle from '../../../../../../../components/Texts/Subtitle';
import CardWrapper from '../../../../../../../components/Cards/CardWrapper/CardWrapper';

import doctorh1 from '../../../../../../../assets/doctors/doctor_h1.jpg'
import doctorh2 from '../../../../../../../assets/doctors/doctor_h2.jpg'
import doctorh3 from '../../../../../../../assets/doctors/doctor_h3.jpg'
import doctorm1 from '../../../../../../../assets/doctors/doctor_m1.jpg'
import doctorm2 from '../../../../../../../assets/doctors/doctor_m2.jpg'
import Caption from '../../../../../../../components/Texts/Caption';
import ContentText from '../../../../../../../components/Texts/ContentText';
import { grey } from '@material-ui/core/colors';
import { RoundedButton } from '../../../../../../../components/Buttons/GeneralButtons';


class AppointmentsCard extends Component {
  render(){

    const {classes} = this.props

    return(
      <Grid container>
        <Grid item xs={12}>
          <Subtitle text='Citas' medium variant='light'/>
        </Grid>
        <Grid item xs={12}>
          <CardWrapper>
            <div className={classes.card}>
              <ContentText text='Próximas citas' medium />
              <Grid container>
                {fakedata.appointments.map((item,key)=>{
                  return(
                    <Grid item xs={12} key={key.toString()}>
                      <SingleAppointment data={item} isLast={key===fakedata.appointments.length-1}/>
                    </Grid>
                  )
                })}
              </Grid>
              <Grid container justify='flex-end'>
                <Grid item>
                  <RoundedButton label='Ver más' color='primary' size='small'/>
                </Grid>
              </Grid>

            </div>
          </CardWrapper>
        </Grid>
      </Grid>
    )
  }
}

const styles = theme => ({
  root:{},
  card:{
    padding:16
  }
})

export default withStyles(styles)(AppointmentsCard)

const fakedata = {
  appointments:[
    {id:1, image:doctorh1, doctor:'Eduardo Cárdenas', date:'Lunes, 9 de diciembre', subject:'Cita de emergencia'},
    {id:2, image:doctorh3, doctor:'Carlos Jimenez', date:'Martes, 10 de diciembre', subject:'USG+Consultoría'},
    {id:3, image:doctorm1, doctor:'Gladys Patrica', date:'Viernes, 20 de diciembre', subject:'Laboratorio'},
    {id:4, image:doctorh1, doctor:'Eduardo Cárdenas', date:'Lunes, 23 de diciembre', subject:'Seguimiento'},
    {id:5, image:doctorm2, doctor:'Adriana Ríos', date:'Lunes, 24 de diciembre', subject:'Seguimiento'},
    
  ]
}

const SingleAppointment = (props) => {

  const {data, isLast} = props

  return(
    <div style={{
      paddingTop:12,
      paddingBottom:12,
      borderBottom: !isLast ? `1px dashed ${grey[300]}` : null
    }}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item>
              <Avatar src={data.image} alt=''/>
            </Grid>
            <Grid item xs>
              <Grid container>
                <Grid item xs={12}>
                  <Caption text={'Dr. '+data.doctor} medium color='primary'/>
                </Grid>
                <Grid item xs={12}>
                  <ContentText text={data.subject} medium variant='bold'/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems='center' spacing={1}>
            <Grid item>
              <Icon fontSize='small' style={{paddingTop:8, color:grey[700]}}>access_time</Icon>
            </Grid>
            <Grid item xs>
              <Caption text={data.date} medium/>
            </Grid>
            <Grid item>
              <IconButton size='small'><Icon>more_horiz</Icon></IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      
    </div>
  )
}