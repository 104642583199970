import React, { Component } from 'react';
import { withStyles, AppBar, IconButton, Icon, Grid } from '@material-ui/core';
import ContentText from '../../../components/Texts/ContentText';
import { palette } from '../../../variables/config';
import { grey } from '@material-ui/core/colors';
import CardWrapper from '../../../components/Cards/CardWrapper/CardWrapper';
import ReactSVG from 'react-svg';

import allergies_icon from '../../../assets/medicalicons/mould.svg'
import notification_icon from '../../../assets/medicalicons/bell.svg'

import Caption from '../../../components/Texts/Caption';
import Title from '../../../components/Texts/Title';
import VitalChart from './components/VitalChart';
import DateMenu from './components/DateMenu';
import VitalMenu from './components/VitalMenu';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import TableMenu from './components/TableMenu';

import {fakedata} from './fakedata'
 

class LabsView extends Component {

  state = {
    selectedDate:new Date(),
    menuSelected:1,
  }

  render(){

    const {classes} = this.props
    const {selectedDate, menuSelected} = this.state

    return(
      <div className={classes.root}>
          <AppBar position='fixed' className={classes.appbar}>
            <div style={{position:'relative'}}>
              <div style={{padding:16}}>
                <ContentText text='Laboratorio' medium color={palette.primary.main} align='center'/>
              </div>
              <div style={{position:'absolute', top:'25%',left:16}}>
                <IconButton size='small' onClick={this.onReturn}>
                  <Icon color='primary'>arrow_back_ios</Icon>
                </IconButton>
              </div>
            </div>
          </AppBar>
          <div className={classes.container}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                
                  <Grid container justify='center' spacing={1}>
                    <Grid item xs={6}>
                      <div className={classes.menu_button}>
                        <TableMenu 
                        selected={menuSelected} 
                        options={fakedata.menuList} 
                        onItemSelected={this.onFilterSelected}/>
                    
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className={classes.date_button}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker 
                            format="dd/MM/yyyy"
                            placeholder='Fecha'
                            value={selectedDate}
                            onChange={this.handleDateChange}
                            InputProps={{
                              disableUnderline: true,
                            }}
                            style={{fontWeight:500, color:palette.primary.main}}
                            
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {fakedata.documents.map((item,key)=>{
                      return(
                        <Grid item xs={12} key={key.toString()}>
                          <div className={classes.item}>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Caption text={item.speciality} color={palette.primary.main} medium/>
                              </Grid>
                              <Grid item item xs={12}>
                                <Grid container>
                                  <Grid item xs>
                                    <Grid container direction='column'>
                                      <Grid item>
                                        <Caption text='Estudio' medium variant='light'/>
                                      </Grid>
                                      <Grid item>
                                        <Caption text={item.description} medium/>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item>
                                    <Grid container direction='column'>
                                      <Grid item>
                                        <Caption text='Fecha' medium variant='light'/>
                                      </Grid>
                                      <Grid item>
                                        <Caption text={item.date} medium/>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        
                      )
                    })}
                  </Grid>
                </Grid>
            </Grid>
          </div>
      </div>
    )
  }

  handleDateChange = (date) => {
    this.setState({selectedDate:date})
  }

  onFilterSelected = (id) => {
    //this.setState({menuSelected:id})
  }

  onReturn = () => {
    this.props.history.goBack()
  }
}

const styles = theme => ({
  root:{
    position:'relative',
  },
  date_button:{
    padding:12,
    background:'white',
    borderRadius:16,
    width:'100%',
    boxSizing:'border-box',
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
  },
  menu_button:{
    padding:12,
    background:'white',
    borderRadius:16,
    width:'100%',
    boxSizing:'border-box',
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
  },
  container:{
    marginTop:56,
    boxSizing:'border-box',
    padding:16
  },
  appbar:{
    background:'white',
    boxShadow:'none',
    //borderBottomLeftRadius:32,
    //borderBottomRightRadius:32,
    borderBottom:`1px solid ${grey[300]}`
  },
  menuItem:{
    padding:24,
    borderBottom:`1px solid ${grey[300]}`
  },
  icon:{
    width:48,
    height:48
  },
  item:{
    padding:12,
    background:'white',
    borderRadius:16,
    width:'100%',
    boxSizing:'border-box',
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
  }
})

export default withStyles(styles)(LabsView)

const BasicCard = props => {

  const {title, value, icon} = props

  return(
    <CardWrapper>
      <div style={{padding:16}}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Caption text={title} align='center' variant='light' medium/>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems='center'>
              <Grid item xs={6}>
                <ReactSVG 
                src={icon} 
                style={{
                  width:52,
                  height:52
                }} 
                beforeInjection={svg => {svg.setAttribute('fill', '#C73794')}}/>
              </Grid>
              <Grid item xs={6}>
                <Title text={value} align='center' medium variant='bold'/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </CardWrapper>
  )
}