import React, { Component } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import ContentText from '../../../../../../components/Texts/ContentText';
import Subtitle from '../../../../../../components/Texts/Subtitle';
import {fakedata} from './fakedata'
import VitalsCard from './components/VitalsCard';
import InfoCard from './components/InfoCard';
import InsuranceCard from './components/InsuranceCard';
import AllergiesCard from './components/AllergiesCard';
import DrugsCard from './components/DrugsCard';
import RemindersCard from './components/RemindersCard';
import AppointmentsCard from './components/AppointmentsCard';
import { RoundedButton } from '../../../../../../components/Buttons/GeneralButtons';


class Vitals extends Component {
  render(){
    return(
      <div>
        <Grid container spacing={3}>
          {/***** VITALS ****/}
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Subtitle text='Signos vitales' medium variant='light'/>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {fakedata.vitals.map((item,key)=>{
                    return(
                      <Grid item xs={12} md={4} key={key.toString()}>
                        <VitalsCard data={item}/>
                      </Grid>
                    )
                  })}
                  
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <InfoCard />
                  </Grid>
                  <Grid item xs={12}>
                    <InsuranceCard />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <AllergiesCard />
                  </Grid>
                  <Grid item xs={12}>
                    <DrugsCard />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <RemindersCard />
                  </Grid>
                  <Grid item xs={12}>
                    <AppointmentsCard />
                  </Grid>
                </Grid>
              </Grid>
              
            </Grid>
          </Grid>
        </Grid>
        
      </div>
    )
  }
}

const styles = theme => ({
  root:{}
})

export default withStyles(styles)(Vitals)