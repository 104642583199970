import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'



import {request_users, request_delete_user, request_welcome, request_zones, request_add_zone, request_delete_zone} from './requests'
import CardWrapper from '../../../../components/Cards/CardWrapper/CardWrapper';
import TableMenu from './Components/TableMenu';
import GenderMenu from './Components/GenderMenu';
import SearchBar from './Components/SearchBar';

import {fakedata} from './fakedata'
import TableContent from './Components/TableContent';


class Doctors extends Component{

  state = {
    search:null,
    isloading:false,
    menuSelected:1,
    gender:1,
    success:{open:false,timeout:2000,type:'success',message:'¡Acción exitosa!'},
  }

  render(){

    const {history, language} = this.props
    const {success, isloading} = this.state
    const content = contentData[language]

    const {menuSelected, gender, search} = this.state

    const doctorlist = this.filterData(fakedata.doctors, menuSelected, gender, search)
    
    return(
      <BasicView
        history={history}
        content={content.layout}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar} 
        isloading={isloading}
      >
        <CardWrapper>
          <div>
            <div style={{padding:16}}>
              <Grid container alignItems='center'>
                <Grid item>
                  <TableMenu selected={menuSelected} options={fakedata.menuList} onItemSelected={this.onFilterSelected}/>
                </Grid>
                <Grid item xs/>
                <Grid item>
                  <GenderMenu selected={gender} options={fakedata.genderList} onChange={this.onChangeGender}/>
                </Grid>
              </Grid>
            </div>
            <div>
              <SearchBar onSearch={this.onSearch}/>
            </div>
            <div>
              <TableContent data={doctorlist}/>
            </div>
          </div>
        </CardWrapper>
        
        
      </BasicView>
      
    )
  }

  componentDidMount(){
  }

  filterData = (data, speciality, gender, search) => {

    // Speciality filter
    let first_list = data.filter(el => {
      if(speciality !== 1){
       // console.log(fakedata.menuList[speciality+1].label)
        return fakedata.menuList[speciality-1].label === el.speciality
      }else{
        return true
      }
    })

    let second_list = first_list.filter(el => {
      if(gender !== 1){
        return fakedata.genderList[gender-1].label === el.gender
      }else{
        return true
      }
    })

    let third_list = second_list.filter(el => {
      if(search !== null && search !== undefined && search !== ''){
        return el.name.includes(search)
      }else{
        return true
      }
    })

    return third_list
  }

  onSearch = (data) => {
    this.setState({search:data})
  }

  onChangeGender = (id) => {
    this.setState({gender:id})
  }

  onFilterSelected = (id) => {
    this.setState({menuSelected:id})
  }



}

export default withStyles(styles)(Doctors)