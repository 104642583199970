import React from 'react'
import { Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';



const styles = {
  text:{
    color:grey[700],
    fontWeight:'400'
  },
  bold:{
    color:grey[900]
  },
  light:{
    color:grey[500],
    fontWeight:'400'
  },
  superlight:{
    color:grey[300]
  },
}

const ContentText = (props) => {

  const {variant, medium, align, color, noWrap} = props
  let textStyles = {...styles.text}

  if(variant){
    switch(variant){
      case 'bold':
        textStyles = {...textStyles, ...styles.bold}
        break;
      case 'light':
        textStyles = {...textStyles, ...styles.light}
        break;
      case 'superLight':
        textStyles = {...textStyles, ...styles.superlight}
        break;
      case 'superBold':
        textStyles = {...textStyles, ...styles.superbold}
        break;

      default:
        break;
    }
  }
  if(color){
    textStyles = {...textStyles, color:color}
  }
  if(medium){
    textStyles = {...textStyles, fontWeight:'500'}
  }

  return(
    <Typography variant='body1' style={textStyles} align={align} noWrap={noWrap}>
      {props.text}
    </Typography>
  )
}

export default ContentText;