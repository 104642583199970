import userh1 from '../../../../assets/patients/user_h1.jpg'
import userh2 from '../../../../assets/patients/user_h2.jpg'
import userh3 from '../../../../assets/patients/user_h3.jpg'
import userh4 from '../../../../assets/patients/user_h4.jpg'
import userm1 from '../../../../assets/patients/user_m1.jpg'
import userm2 from '../../../../assets/patients/user_m2.jpg'
import userm3 from '../../../../assets/patients/user_m3.jpg'
import userm4 from '../../../../assets/patients/user_m4.jpg'
import userm5 from '../../../../assets/patients/user_m5.jpg'
import userm6 from '../../../../assets/patients/user_m6.jpg'
//import doctorm3 from '../../../../assets/doctors/doctor_m3.jpg'

export const fakedata = {
  menuList: [
    {id:1, label:'Todo'},
  ],
  genderList: [
    {id:1, label:'Todo'},
    {id:2, label:'Hombre'},
    {id:3, label:'Mujer'},
  ],

  doctors:[
    {id:1, image:userm1, age:23, blood_type:'B+', gender:'Mujer',name:'Sara Rama Barreda', email:'srama@medinube.com', phone:'5512976919', address:'795 Folsom Ave, Suite 600 San Francisco, CADGE 94107'},
    {id:2, image:userh1, age:27, blood_type:'O+', gender:'Hombre',name:'Sergio Luis Aramendi', email:'saramendi@medinube.com', phone:'5530678400', address:'795 Folsom Ave, Suite 600 San Francisco, CADGE 94107'},
    {id:3, image:userh2, age:34, blood_type:'O+', gender:'Hombre',name:'Felix Carlos Ros', email:'fros@medinube.com', phone:'5555099062', address:'795 Folsom Ave, Suite 600 San Francisco, CADGE 94107'},
    {id:4, image:userm2, age:36, blood_type:'AB+', gender:'Mujer',name:'Maria Granada Montoliu', email:'mgranada@medinube.com', phone:'5550943334', address:'795 Folsom Ave, Suite 600 San Francisco, CADGE 94107'},
    {id:5, image:userm3, age:33, blood_type:'B+', gender:'Mujer',name:'Noemi Maria Urrea', email:'nurrea@medinube.com', phone:'5558708303', address:'795 Folsom Ave, Suite 600 San Francisco, CADGE 94107'},
    {id:6, image:userh3, age:41, blood_type:'O+', gender:'Hombre',name:'Vicente Angel Lopera', email:'vlopera@medinube.com', phone:'5559811459', address:'795 Folsom Ave, Suite 600 San Francisco, CADGE 94107'},
    {id:7, image:userm4, age:35, blood_type:'AB+', gender:'Mujer',name:'Adela Maria Sierra', email:'asierra@medinube.com', phone:'5557151670', address:'795 Folsom Ave, Suite 600 San Francisco, CADGE 94107'},
    {id:8, image:userh4, age:30, blood_type:'AB-', gender:'Hombre',name:'Pedro Emilio Solanes', email:'psolanes@medinube.com', phone:'555101988', address:'795 Folsom Ave, Suite 600 San Francisco, CADGE 94107'},
    {id:9, image:userm5, age:24, blood_type:'AB-', gender:'Mujer',name:'Maria Arminda Veloso',  email:'marminda@medinube.com', phone:'5555059792', address:'795 Folsom Ave, Suite 600 San Francisco, CADGE 94107'},  
  ]

}