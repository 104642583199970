import React, { Component } from 'react';
import { withStyles, AppBar, IconButton, Icon, Grid } from '@material-ui/core';
import ContentText from '../../../components/Texts/ContentText';
import { palette } from '../../../variables/config';
import { grey } from '@material-ui/core/colors';
import CardWrapper from '../../../components/Cards/CardWrapper/CardWrapper';
import ReactSVG from 'react-svg';

import allergies_icon from '../../../assets/medicalicons/mould.svg'
import notification_icon from '../../../assets/medicalicons/bell.svg'

import Caption from '../../../components/Texts/Caption';
import Title from '../../../components/Texts/Title';
import VitalChart from './components/VitalChart';
import DateMenu from './components/DateMenu';
import VitalMenu from './components/VitalMenu';
import { fakedata } from './fakedata';
 

class VitalsView extends Component {

  state = {
    selected:1,
  }

  render(){

    const {classes} = this.props
    const {selected} = this.state

    return(
      <div className={classes.root}>
          <AppBar position='fixed' className={classes.appbar}>
            <div style={{position:'relative'}}>
              <div style={{padding:16}}>
                <ContentText text='Signos Vitales' medium color={palette.primary.main} align='center'/>
              </div>
              <div style={{position:'absolute', top:'25%',left:16}}>
                <IconButton size='small' onClick={this.onReturn}>
                  <Icon color='primary'>arrow_back_ios</Icon>
                </IconButton>
              </div>
            </div>
          </AppBar>
          <div className={classes.container}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <BasicCard 
                  title='Alergias'
                  value={3}
                  icon={allergies_icon}
                />
              </Grid>
              <Grid item xs={6}>
                <BasicCard 
                  title='Notificaciones'
                  value={4}
                  icon={notification_icon}
                />
              </Grid>
              <Grid item xs={12}>
                <VitalChart data={fakedata.dataSigns[selected]}/>
              </Grid>
              <Grid item xs={12}>
                <DateMenu/>
              </Grid>
              <Grid item xs={12}>
                <VitalMenu selected={selected} onChange={this.onChange}/>
              </Grid>
            </Grid>
          </div>
      </div>
    )
  }

  onReturn = () => {
    this.props.history.goBack()
  }

  onChange = (event, value) => {
    this.setState({selected:value})
  }
}

const styles = theme => ({
  root:{
    position:'relative',
  },
  container:{
    marginTop:56,
    boxSizing:'border-box',
    padding:16
  },
  appbar:{
    background:'white',
    boxShadow:'none',
    //borderBottomLeftRadius:32,
    //borderBottomRightRadius:32,
    borderBottom:`1px solid ${grey[300]}`
  },
  menuItem:{
    padding:24,
    borderBottom:`1px solid ${grey[300]}`
  },
  icon:{
    width:48,
    height:48
  }
})

export default withStyles(styles)(VitalsView)

const BasicCard = props => {

  const {title, value, icon} = props

  return(
    <CardWrapper>
      <div style={{padding:16}}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Caption text={title} align='center' variant='light' medium/>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems='center'>
              <Grid item xs={6}>
                <ReactSVG 
                src={icon} 
                style={{
                  width:52,
                  height:52
                }} 
                beforeInjection={svg => {svg.setAttribute('fill', '#C73794')}}/>
              </Grid>
              <Grid item xs={6}>
                <Title text={value} align='center' medium variant='bold'/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </CardWrapper>
  )
}