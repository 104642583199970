import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography, Icon, Fab} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import WarningModal from '../../../components/Modals/WarningModal'

import chart_sample from '../../../assets/mobile/chartSample.png'


import {request_users, request_delete_user} from './requests'
import {cleanData2Table} from './customFunctions'
import EnergyGauge from './components/EnergyGauge';
import { grey } from '@material-ui/core/colors';
import CardWrapper from '../../../components/Cards/CardWrapper/CardWrapper';
import ActivityCard from './components/ActivityCard';
import Subtitle from '../../../components/Texts/Subtitle';
import ProfileCard from './components/ProfileCard';
import VitalsCard from './components/VitalsCard';

import {fakedata} from './fakedata'
import ReminderCard from './components/ReminderCard';

class Dashboard extends Component{

  state = {
    isloading:false,
    users:[],
    filter:null,
    modals:{
      warning_delete_user:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'¡Acción exitosa!'},
  }

  onNavigate = () => {
    this.props.history.push('/apps')
  }

  render(){

    const {classes, history, language,translations} = this.props
    const {users,  modals, success, filter, tableConfig} = this.state
    const content = contentData[language]

    return(
      <div className={classes.root}>
        <div style={{position:'fixed', bottom:70, right:16, zIndex:3}}>  
          <Fab color='primary' onClick={this.onNavigate}>
            <Icon>
              apps
            </Icon>
          </Fab>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ProfileCard />
          </Grid>
          <Grid item xs={12}>
            <ActivityCard />

          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {fakedata.vitals.map((item,key)=>{
                return(
                  <Grid item xs={6}key={key.toString()}>
                    <VitalsCard data={item}/>
                  </Grid>
                )
              })}
              
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ReminderCard data={fakedata.today_reminders}/>
          </Grid>
        </Grid>
      </div>
      
    )
  }

  
}
 
export default withStyles(styles)(Dashboard)