import {grey} from '@material-ui/core/colors';
import {SideBar} from '../../variables/config.jsx';


const styles = theme => ({
  root:{
    height:'100vh',
    overflowX:'hidden',
    paddingLeft:4,
    boxSizing:'border-box'
  },
  mainlogo:{
    margin:'auto',
    width:84,
    height:84,
    padding:12,
  },
  mainLogoContainer:{
    marginTop:24,
    background:'white',
    borderRadius:'50%',
  },
  logoTitle:{
    color:grey[800],
  },
  hide: {
    display: 'none',
  },
  
  drawerPaper:{
    background:theme.palette.primary.main,
    borderTopRightRadius:16,
    borderBottomRightRadius:16,
    background:theme.palette.primary.main,
    overflow: 'hidden',
    position:'relative',
    width: `${SideBar.width}px`,
    margin:'0px',
    border:'0px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.enteringScreen,
    }),

  },
  drawerPaperClose: {
    background:theme.palette.primary.main,
    overflow: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `${SideBar.minWidth}px`,
  },
  logo: {
    display:'block',
    margin:'auto',
    width: '80%',
    padding: '10px'
  },
  avatar: {
    display:'block',
    margin:'auto',
    width: '50%',
    padding: '10px',
    borderRadius:'50%',
  },
  avatar2: {
    display:'block',
    margin:'auto',
    width: '30%',
    padding: '10px',
    borderRadius:'90%',
  },
  button:{
    background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main} )`,
    borderRadius:'40px',
    padding: '3px 10px',
    marginTop: '20px',
    color:'white',
    height:'40px',
    width:'200px'
    
  },
  pad:{
   
  },
  color: {
    color: 'white',
    
  },
  title:{
    textAlign: 'center',
    fontWeight: 'bold',
  },
  title2:{
    color:grey[600],
    fontWeight:'500',
   
  },
  title3:{
    color:grey[600],
    fontWeight:'500',
    
  },
  icon:{
    color:grey[700]
  },
  bor: {
    border: '1px solid red'
  }
});

export default styles;
