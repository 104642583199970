import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Subtitle from '../../../../../../../components/Texts/Subtitle';
import CardWrapper from '../../../../../../../components/Cards/CardWrapper/CardWrapper';

import allergy_icon from '../../../../../../../assets/medicalicons/medicine.svg'
import ReactSVG from 'react-svg';
import ContentText from '../../../../../../../components/Texts/ContentText';
import { RoundedButton } from '../../../../../../../components/Buttons/GeneralButtons';

class DrugsCard extends Component {
  render(){

    const {classes} = this.props

    return(
      <Grid container>
        <Grid item xs={12}>
          <Subtitle text='Medicamentos' medium variant='light'/>
        </Grid>
        <Grid item xs={12}>
          <CardWrapper>
            <div className={classes.card}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container justify='center'>
                    <Grid item>
                      <ReactSVG 
                        src={allergy_icon} 
                        className={classes.icon} 
                        beforeInjection={svg => {svg.setAttribute('fill', '#C73794')}}/>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <HeaderWrapper label='Medicamento' data='Periodo'/>
                    </Grid>
                    <Grid item xs={12}>
                      <DataWrapper 
                        label={'Paracetamol'} 
                        data={'3 días'}/>
                    </Grid>
                    <Grid item xs={12}>
                      <DataWrapper 
                        label={'Amoxicilina '} 
                        data={'8 hrs'}/>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{height:100}}/>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify='flex-end'>
                    <Grid item>
                      <RoundedButton label='Detalles' color='primary' size='small'/>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </CardWrapper>
        </Grid>
      </Grid>
    )
  }
}

const styles = theme => ({
  root:{},
  card:{
    padding:16,
    paddingTop:32,
    paddingBottom:16,
  },
  icon:{
    width:52,
    height:52
  }
})

export default withStyles(styles)(DrugsCard)

const content = {
  provider:'Seguros Monterrey',
  plan:'Alfa Medical Flex',
  zone:'CDMX',
  policy_number:'FA152650',
  ammount:'$100,000,000.00'
}

const DataWrapper = (props) => {

  const {label, data} = props

  return(
    <Grid container spacing={1} wrap='nowrap'>
      <Grid item xs>
        <ContentText text={label} medium variant='bold'/>
      </Grid>
      <Grid item>
        <ContentText text={data} medium variant='light'/>
      </Grid>
    </Grid>
  )
}

const HeaderWrapper = (props) => {

  const {label, data} = props

  return(
    <Grid container spacing={1} wrap='nowrap'>
      <Grid item xs>
        <ContentText text={label} medium />
      </Grid>
      <Grid item>
        <ContentText text={data} medium />
      </Grid>
    </Grid>
  )
}