import React, {Component} from 'react'
import { withStyles, Grid, Typography } from '@material-ui/core';
import ContentText from '../../../../components/Texts/ContentText';
import { grey, cyan, green } from '@material-ui/core/colors';
import ReactApexChart from 'react-apexcharts';
import { palette } from '../../../../variables/config';

class EnergyGauge extends Component {

  state = {
    options: {
      hollow:{

      },
      colors: ['#fe3082', '#61e800', '#0ff'],
      plotOptions: {
        radialBar: {
          hollow: {
            size: '15%',
            background: 'transparent',
        },
          track: {
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              blur: 4,
              opacity: 0.15
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              show: false
            }
          }
        }
      },
      stroke: {
        lineCap: "round"
      },
      labels: ["Progress"]
        
        
    },
    series: [67,50,25],
  }


  render(){

    const {classes, size} = this.props

    return(
          <div className={classes.root}>
            <ReactApexChart 
              options={this.state.options} 
              series={this.state.series} 
              type="radialBar" 
              height={size ? size : 250} />
          </div>
    )
  }
}

const styles = (theme) => ({
  root:{
  },
  text:{
    color:grey[700],
    fontWeight:'600'
  }
})

export default withStyles(styles)(EnergyGauge)