import React, { Component } from 'react';
import { Grid, Avatar } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Subtitle from '../../../../../../../components/Texts/Subtitle';
import CardWrapper from '../../../../../../../components/Cards/CardWrapper/CardWrapper';

import userh1 from '../../../../../../../assets/patients/user11.png'
import ContentText from '../../../../../../../components/Texts/ContentText';

class InfoCard extends Component {
  render(){

    const {classes} = this.props
    console.log(content)
    return(
      <Grid container>
        <Grid item xs={12}>
          <Subtitle text='Datos principales' medium variant='light'/>
        </Grid>
        <Grid item xs={12}>
          <CardWrapper>
            <div className={classes.card}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container justify='center'>
                    <Grid item>
                      <Avatar src={userh1} alt='' style={{width:100, height:100}}/>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <ContentText text={content.name} align='center' medium variant='bold'/>
                </Grid>
                <Grid item xs={12}>
                  <ContentText text={content.email} align='center' medium />
                </Grid>
                <Grid item xs={12}>
                  <ContentText text={content.phone} align='center' medium variant='light'/>
                </Grid>
                <Grid item xs={12}/>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <DataWrapper label={'Edad'} data={`${content.age} años`}/>
                    </Grid>
                    <Grid item xs={6}>
                      <DataWrapper label={'Estatura'} data={`${content.height} cm`}/>
                    </Grid>
                    <Grid item xs={6}>
                      <DataWrapper label={'Peso'} data={`${content.weight} kg`}/>
                    </Grid>
                    <Grid item xs={6}>
                      <DataWrapper label={'Grasa'} data={`${content.fat} %`}/>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </CardWrapper>
        </Grid>
      </Grid>
    )
  }
}

const styles = theme => ({
  root:{},
  card:{
    padding:16,
    paddingTop:32,
    paddingBottom:32
  }
})

export default withStyles(styles)(InfoCard)

const content = {
  name:'Sergio Luis Aramedi',
  email:'saramedi@medinube.com',
  phone:'5530678400',
  age:34,
  height:176,
  weight:68,
  fat:14.6
}

const DataWrapper = (props) => {

  const {label, data} = props

  return(
    <Grid container spacing={1} wrap='nowrap'>
      <Grid item>
        <ContentText text={label} medium variant='light'/>
      </Grid>
      <Grid item xs>
        <ContentText text={data} medium/>
      </Grid>
    </Grid>
  )
}