import {authActions} from '../actionTypes';
import {updateObject} from '../../shared/utility.jsx';

const initialState = {
    authData: null,
    error: null,
    loading: false
};

const startAuth = (state,action) => {
  return updateObject(state, {loading:true});
};

const successAuth = (state,action) => {
  return updateObject(state, {authData:action.authData, error:false,loading:false});
};

const failAuth = (state,action) => {
  return updateObject(state, {error:action.error,loading:false});
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authActions.LOGIN_REQUEST: return startAuth(state,action);
    case authActions.LOGIN_SUCCESS: return successAuth(state,action);
    case authActions.LOGIN_ERROR: return failAuth(state,action);
    default:
      return state;
  }
};

export default reducer;
