export const fakedata = {
  menuList: [
    {id:1, label:'Resumen'},
    {id:2, label:'Demografía'},
    {id:3, label:'Imágenes'},
    {id:4, label:'Prescipciones'},
    {id:5, label:'Laboratorio'},
  ],
  genderList: [
    {id:1, label:'Todo'},
    {id:2, label:'Hombre'},
    {id:3, label:'Mujer'},
  ],
}