import {Home} from '../actionTypes';
import {updateObject} from '../../shared/utility.jsx';

const initialState = {
    currentUser: null,
    isloading: false,
    issucceed: false,
    iserror: false,
    ispostloading:false
};

const startHome = (state,action) => {
  return updateObject(state, {loading:true});
};

const successHome = (state,action) => {
  return updateObject(state, {error:false,loading:false});
};


const failHome = (state,action) => {
  return updateObject(state, {error:action.error,loading:false});
};

const updateProcessState = (state,action) => {
  return updateObject(state, {...action.processState});
};


const reducer = (state = initialState, action) => {
  switch (action.type) {

    case Home.HOME_START: return startHome(state,action);
    case Home.HOME_SUCCESS: return successHome(state,action);
    case Home.HOME_ERROR: return failHome(state,action);
    case Home.HOME_PROCESS_STATE: return updateProcessState(state,action);
  
    default:
      return state;
  }
};

export default reducer;
