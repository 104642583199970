export const fakedata = {
  menuList: [
    {id:1, label:'Todo'},
    {id:2, label:'Neurología'},
    {id:3, label:'Ortopedia'},
    {id:4, label:'Ginecología'},
    {id:5, label:'Radioterapia'},
    {id:6, label:'General'},
  ],
  documents:[
    {id:1, speciality:'General', description:'Metformina', date:'18/11/2019'},
    {id:2, speciality:'Neurología', description:'Vitamina B12', date:'17/11/2019'},
    {id:3, speciality:'Neurología', description:'Gapanpetina', date:'17/11/2019'},
    {id:4, speciality:'Radioterapia', description:'Estudios de emergencia', date:'14/11/2019'},
    {id:5, speciality:'Ortopedia', description:'Rayos X', date:'10/11/2019'},
  ]
}