import {masterActions} from '../actionTypes';
import {updateObject} from '../../shared/utility.jsx';

const initialState = {
    language:'spanish',
    catalogs:{},
    currentUser: null,
    isloading: false,
    issucceed: false,
    iserror: false,
    ispostloading:false
};

const startMaster = (state,action) => {
  return updateObject(state, {loading:true});
};

const successMaster = (state,action) => {
  return updateObject(state, {error:false,loading:false});
};

const updateCurrentUser = (state,action) => {
  return updateObject(state, {currentUser:action.userdata, error:false,loading:false});
};

const failMaster = (state,action) => {
  return updateObject(state, {error:action.error,loading:false});
};

const changeLanguageMaster = (state,action) => {
  return updateObject(state, {language:action.language,error:false,loading:false});
}

const updateProcessState = (state,action) => {
  return updateObject(state, {...action.processState});
};

const updateData = (state,action) => {
  return updateObject(state, {...action.data});
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case masterActions.MASTER_START: return startMaster(state,action);
    case masterActions.MASTER_SUCCESS: return successMaster(state,action);
    case masterActions.MASTER_ERROR: return failMaster(state,action);
    case masterActions.MASTER_CHANGE_LANGUAGE: return changeLanguageMaster(state,action);
    case masterActions.MASTER_UPDATE_CURRENT_USER: return updateCurrentUser(state,action);
    case masterActions.MASTER_PROCESS_STATE: return updateProcessState(state,action);
    case masterActions.MASTER_DATA_UPDATE: return updateData(state,action);
    default:
      return state;
  }
};

export default reducer;
