import Dashboard from '../views/Admin/Dashboard/Dashboard'
import Doctors from '../views/Admin/Doctors/Doctors/Doctors';
import Patients from '../views/Admin/Patients/Patients/Patients';
import PatientView from '../views/Admin/Patients/PatientView/PatientView';
import FunctionalityView from '../views/Admin/FunctionalityView/FunctionalityView';
import VitalsView from '../views/Admin/VitalsView/VitalsView';
import DocumentsView from '../views/Admin/DocumentsView/DocumentsView';
import PresciptionsView from '../views/Admin/PresciptionsView/PresciptionsView';
import LabsView from '../views/Admin/LabsView/LabsView';




const dashboardAdmin = {
    sections:[
        {
            path: "/",
            icon:'dashboard',
            label:'Home',
            component: Dashboard,
            sidebar:true,
            exact:true,
            level:4
        },
        {
            path: "/apps",
            icon:'dashboard',
            label:'Home',
            component: FunctionalityView,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/vitals",
            icon:'dashboard',
            label:'Vitals',
            component: VitalsView,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/images",
            icon:'dashboard',
            label:'Doucmentos',
            component: DocumentsView,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/prescriptions",
            icon:'dashboard',
            label:'Doucmentos',
            component: PresciptionsView,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/labs",
            icon:'dashboard',
            label:'Doucmentos',
            component: LabsView,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/doctors",
            icon:'doctor',
            label:'Doctores',
            component: Doctors,
            sidebar:true,
            exact:true,
            level:4
        },
        {
            path: "/patients",
            icon:'patient',
            label:'Pacientes',
            component: Patients,
            sidebar:true,
            exact:true,
            level:4
        },
        {
            path: "/patients/view",
            icon:'patient',
            label:'Pacientes Detalles',
            component: PatientView,
            sidebar:false,
            exact:true,
            level:4
        },

        { redirect: true, path: "/", to: "/", navbarName: "Redirect" }
    ]
}

export default dashboardAdmin;