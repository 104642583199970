import React, { Component } from 'react';
import { Paper, withStyles, Tabs, Tab } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

class DateMenu extends Component {

  state = {
    selected:0,
  }

  render(){

    const {classes} = this.props
    const {selected} = this.state

    return(
      <Paper className={classes.paper}>
        <Tabs
          value={selected}
          onChange={this.onChange}
          
          variant="scrollable"
          scrollButtons="auto"
          variant="fullWidth"
          TabIndicatorProps={{style: {backgroundColor: "transparent"}}}

        >
          <CustomTab label='1M'/>
          <CustomTab label='3M'/>
          <CustomTab label='6M'/>
          <CustomTab label='12M'/>
        </Tabs>
      </Paper>
    )
  }

  onChange = (event, value) => {
    this.setState({selected:value})
  }
}

const styles = theme => ({
  root:{},
  paper:{
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
  }
})

export default withStyles(styles)(DateMenu)

const CustomTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color:grey[400],
    '&:hover': {
      opacity: 1,
    },
    '&$selected': {
      background:theme.palette.primary.main,
      color: 'white',
      fontWeight: theme.typography.fontWeightMedium,
      borderRadius:4,
    },
    '&:focus': {
    },
  },
  selected: {},
}))(props => <Tab {...props} />);