import React, { Component } from 'react';
import { withStyles, Grid, Typography, Icon } from '@material-ui/core';
import CardWrapper from '../../../../components/Cards/CardWrapper/CardWrapper';
import EnergyGauge from './EnergyGauge';
import { grey } from '@material-ui/core/colors';
import ContentText from '../../../../components/Texts/ContentText';
import Subtitle from '../../../../components/Texts/Subtitle';
import Caption from '../../../../components/Texts/Caption';
//import moment from 'moment'


class ActivityCard extends Component {
  render(){

    const {classes} = this.props

    return(
      <div>
        <CardWrapper>
          <div style={{padding:8}}>
            <Grid container alignItems='center' spacing={1}>
              
              <Grid item xs={6}>
                <EnergyGauge size={250}/>
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <DataWrapper
                      title='Movimiento'
                      value='310/540'
                      unit='calorías'
                      color='#fe3082'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DataWrapper
                      title='Ejercicio'
                      value='20/40'
                      unit='minutos'
                      color='#61e800'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DataWrapper
                      title='Reposo'
                      value='3/12'
                      unit='horas'
                      color='#0ff'
                      isLast
                    />
                  </Grid>

                  {/*<Grid item xs={12}>
                    <Grid container spacing={0} justify='center' alignItems='flex-end'>
                      <Grid item>
                        <Typography variant='h3' className={classes.step_number}>4178</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='h6' style={{color:grey[700]}}>pasos</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify='center' alignItems='center'>
                      <Grid item>
                        <Icon className={classes.icon}>favorite</Icon>
                      </Grid>
                      <Grid item>
                        <Typography variant='h3' style={{color:grey[900]}}>60</Typography>
                      </Grid>
                    </Grid>
    </Grid>*/}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </CardWrapper>
      </div>

    )
  }
}

const styles = theme => ({
  root:{},
  step_number:{
    color:theme.palette.primary.main,
  },
  icon:{
    color:theme.palette.primary.main,
    fontSize:64
  }
})

export default withStyles(styles)(ActivityCard)

const DataWrapper = props => {

  const {title, value, unit, color, isLast} = props

  return(
    <div style={{
      paddingTop:8, 
      paddingBottom:8, 
      borderBottom: isLast ? null : `1px dashed ${grey[300]}`}}>
      <Grid container>
        <Grid item xs={12}>
          <ContentText text={title} medium color={color}/>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems='baseline' spacing={1}>
            <Grid item>
              <Subtitle text={value} medium variant='bold'/>
            </Grid>
            <Grid item>
              <Caption text={unit} medium/>
            </Grid>
          </Grid>
          
        </Grid>
      </Grid>
    </div>
    
  )
}