import {authActions} from '../actionTypes';

export const authStart = () => {
  return{
    type: authActions.LOGIN_REQUEST
  }
};

export const authSuccess = (authData) => {
  return {
    type: authActions.LOGIN_SUCCESS,
    authData: authData
  };
};

export const authFail = (error) => {
  return{
    type: authActions.LOGIN_ERROR,
    error: error
  }
};

export const logout = () => {
  return{
    type: authActions.USER_SAGA_LOGOUT,
  }
}

export const auth = (username,password) => {
  return{
    type: authActions.AUTH_SAGA,
    username: username,
    password: password
  }
}

export const reauth = () => {
  return{
    type: authActions.AUTH_SAGE_REAUTH
  }
}
