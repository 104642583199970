import React, { Component } from 'react';
import { withStyles, Grid, Typography } from '@material-ui/core';
import CardWrapper from '../../../../components/Cards/CardWrapper/CardWrapper';
import ContentText from '../../../../components/Texts/ContentText';
import Caption from '../../../../components/Texts/Caption';
import { grey } from '@material-ui/core/colors';

import {ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts'
import { palette } from '../../../../variables/config';
import ReactSVG from 'react-svg';

const dataAux = [
  {date: '8 Nov, 2019', data: 36.5},
  {date: '10 Nov, 2019', data: 36.2},
  {date: '12 Nov, 2019', data: 36.4},
  {date: '14 Nov, 2019', data: 36.6},
  {date: '16 Nov, 2019', data: 36.5},
  {date: '18 Nov, 2019', data: 36.3},
  {date: '20 Nov, 2019', data: 36.5},
  {date: '8 Nov, 2019', data: 36.6},
  {date: '10 Nov, 2019', data: 36.6},
  {date: '12 Nov, 2019', data: 36.5},
];


class VitalsCard extends Component {
  render(){

    const {classes, data} = this.props

    const range = this.getMinMaxValues(dataAux)

    const selection = 1
    let contentView = null

    return(
      <div>
        <CardWrapper>
          <div className={classes.card}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container direction='column'>
                  <Grid item>
                    <ContentText text={data ? data.title : ''} variant='bold' medium/>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography variant='h6' className={classes.number}>
                          {`${data ? data.last_report.value : ''}`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2' className={classes.number}>
                          {`${data ? data.last_report.unit : ''}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    
                    
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <ReactSVG 
                  src={data.image} 
                  className={classes.icon} 
                  beforeInjection={svg => {svg.setAttribute('fill', '#C73794')}}/>
              </Grid>
            </Grid>
          </div>
        </CardWrapper>
        
      </div>
    )
  }

  getMinMaxValues = (data) => {
    let min = Infinity
    let max = -Infinity

    data.forEach(item => {
      if(item.data > max){
        max = item.data
      }
      if(item.data < min){
        min = item.data
      }
    })
    return {
      min:min,
      max:max
    }
  }
}

const styles = theme => ({
  root:{},
  card:{
    padding:16
  },
  number:{
    paddingTop:8,
    paddingBottom:8,
    color:grey[900]
  },
  icon:{
    width:52,
    height:52
  }
})

export default withStyles(styles)(VitalsCard)
