import React from 'react';
import { withStyles,  } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import {Typography,Avatar, Grid} from '@material-ui/core';

import nouser from '../../assets/nouser.png'
const imageURL = 'https://oncopassport-dev-public.s3.us-east-2.amazonaws.com/'

const styles = {
  avatar:{
    width:'64px',
    height:'64px',
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
  },
  noavatar:{
    width:'64px',
    height:'64px',
    borderRadius:'50%',
    color:'blue',
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .24)',
  },
  icon:{
    padding:'20px 25px',
    fontSize:'200px',
    color:grey[400]
  },
  name:{
    color:'white',
    fontWeight:'500',

  },
  usertype:{
    color:grey[700],
    fontWeight:'500',
  }

}

const SidebarAvatar = (props) => {

  const {image, name, user_type} = props;

  let avatarContent = null;

  if(image && image !== "" && image !== "0" && image!== 0){
    avatarContent = (
      <Avatar 
        alt={nouser}
        src={imageURL + image}
        style={styles.avatar}
      />
    )
  }else{
    avatarContent = (
      <Avatar 
        alt={nouser}
        src={nouser}
        style={styles.avatar}
      />
    )
  }

  return(
    <div>
      <Grid container direction='column' alignItems='center' spacing={1}>
        <Grid item>
          {avatarContent}
        </Grid>
        <Grid item>
          <Grid container direction='column' alignItems='center'>
            <Grid item>
              <Typography variant='body2' align='center' style={styles.name}>
                {'Dr. ' + name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        
      </Grid>
      

    </div>
  )
}

export default SidebarAvatar;